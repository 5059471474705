<template>
  <div>
    <b-form @submit="enviarVale" v-if="permisos">

      <b-button @click="crearVale" variant="success">Comenzar captura de datos para vale</b-button>
      <div v-if="estadoEncuesta=='En curso'">
        <div v-for="pregunta in vale.datos" :key="pregunta.nombre" class="mb-1" >
          <br>
          <h6>{{pregunta.nombre}}</h6>
          <b-form-select v-if="pregunta.tipo=='Múltiple'" :options="pregunta.opciones" v-model="pregunta.valor"></b-form-select>
          <b-form-input v-if="pregunta.tipo=='Númerico'" v-model="pregunta.valor"></b-form-input>
          <b-form-checkbox v-if="pregunta.tipo=='Si / No'" v-model="pregunta.valor"></b-form-checkbox>


        </div>

        <b-button type="submit" variant="primary">Generar vale</b-button>

        <b-button variant="dark" @click="reloadPage">Dar de alta otro precio</b-button>

      </div>

      <b-card class="mt-3" header="Form Data Result">
        <pre class="m-0">{{ vale }}</pre>
      </b-card>

    </b-form>

  </div>

</template>

<script>
import axios from 'axios'
  export default {
    data() {
      return {
        permisos: true,


        estadoEncuesta:"",
        //Encuesta actual
        vale:{ 
          "idEncuesta": "", 
          "datos": [ 
            { "no": "1", "nombre": "Vigencia", "tipo": "Númerico", valor:""},
            { "no": "2", "nombre": "No Vale", "tipo": "Númerico",  valor:""}, 
            { "no": "3", "nombre": "No Litros", "tipo": "Númerico",  valor:""},
            { "no": "4", "nombre": "Tipo de combustible", "tipo": "Múltiple", "opciones": ["Gasolina regular", "Gasolina premium", "Diesel"], valor:"" },
            { "no": "5", "nombre": "Vehiculo", "tipo": "Múltiple", "opciones": ["marca: 'Ford', submarca: 'Figo', modelo: '2018', color: 'Rojo', serie:'ckjnj78374hbhhx', numero_economico:'003', placas: 'NWA3592', n_inventario: '02324', n_litros: '50'"], valor:"" },
            { "no": "6", "nombre": "Persona", "tipo": "Múltiple", "opciones": ["nombre: 'Sergio', apellido_paterno: 'Rios', apellido_materno: 'Cruz', rfc: 'LOAA9503115H1'"], valor:"" },
            { "no": "7", "nombre": "Centro de carga", "tipo": "Múltiple", "opciones": ["ID: '001', ubicacion: 'CARRETRA MEXICO TULANCIGO KM 32', proveedor: 'PEMEX MEXICO - TULANCINGO'"], valor:"" },
            { "no": "8", "nombre": "Departamento", "tipo": "Múltiple", "opciones": ["ID: '001', dependencia_general: 'Catastro', dependencia_auxiliar: 'Cobranza', responsable: 'Ivan Lopez Alvarez'"], valor:"" },
            { "no": "9", "nombre": "Costos", "tipo": "Múltiple", "opciones": ["ID: '001', identificador_centro: '1090', gasolina_regular: '$22', gasolina_premium:'$25', diesel: '$23', fecha:'17/01/2024'"], valor:"" },
          
          
          ] 
        }

      }
    },
    methods: {
      crearVale(){
        this.estadoEncuesta = 'En curso';
        ////acceso al microfono
        //acceso al gps
      },

      reloadPage() {
      window.location.reload();
      },

      enviarVale(event) {
        event.preventDefault()
        alert(JSON.stringify(this.vale));
        /*COLCOAR PARTE PARA MANDAR AL SERVIDOR*/
        axios({
          method: 'get',
          url: 'https://recaudacion.gobinet.mx/servicios',
          params:{"codeClient":"calimaya"},
          data: {
              "idToken": '',
              "usuario":this.usuario,
              "pass":this.pass
          }
        })
        .then(res => {
            this.resultado = res.data;
        }).catch(err => {
            //errores
            alert(err);
        });


        
      },
    }
  }
</script>